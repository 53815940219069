import axios from '@/libs/axios'

class DataService {
  getAll(page) {
    return axios.get("/banners?page=" + page);
  }
  get(id) {
    return axios.get(`/banners/${id}`);
  }
  create(data) {
    return axios.post("/banners", data);
  }
  update(id, data) {
    return axios.put(`/banners`, data);
  }
  delete(id) {
    return axios.delete(`/banners/${id}`);
  }
  getBannerCount() {
    // return axios.get(`/banners`);
    return axios.get("/users?accountType=2&page=1");

  }

  getServices() {
    return axios.get("/services");

  }
}
export default new DataService();