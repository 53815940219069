<template>

  <b-card-code
    no-body
  >
    
      <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
  
        <add-new
          :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"    
          :dataSlider="sidebarData"  
          :serviceType="serviceType"
          @refreshData="refreshData"
        />

        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
        <b-button
            @click="addNewData"
            variant="outline-primary"
            class="mb-2"
          >
            إضافة 
          </b-button>
          

          <b-table 
            responsive
            :items="items" 
            :fields="tableColumns" 
            striped 
            class="position-relative"
          >

          <template #cell(id)="data">
              {{data.index + 1}}
          </template>


          <template #cell(image)="data">
            <b-avatar :src="data.value" />
          </template>

          

          <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                

                <b-dropdown-item @click="editData(data.item)" >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item>

                

                <b-dropdown-item @click="deleteData(data.item.id)" > 
                  <feather-icon icon="TrashIcon" />
                  <span  class="align-middle ml-50">حذف</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>

          </b-table>

          <div class="demo-spacing-0">
            <b-pagination
              :value="1"
              
              :total-rows="pagination_total"
              :per-page="pagination_per_page"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              @input="(value)=>getData(value)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        

        </b-overlay>
      <!-- </div> -->
  </b-card-code>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton,BCardBody, BPagination  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Swal from "sweetalert2";

export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode,
    BPagination,
  },

  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      serviceType: [],
      pagination_per_page: 20,
      pagination_total: 100,

      currentPage: 1,
      perPage: 1,
      rows: 200,

      tableColumns: [
        { key: 'id', label:'الرقم', sortable: true },
        { key: 'title', label: 'العنوان', sortable: true },
        { key: 'image', label: 'الصورة', sortable: true },
        { key: 'actions', label: 'الإجراءات' },
      ],
     
      
    }
  },


  methods: {
    getData(page){
      this.isLoading = true;
      DataService.getAll(page)
      .then(response => {
        this.getBannerCount()
        this.isLoading = false
        // this.items = response.data.data
        this.items = response.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
    getBannerCount() {
      DataService.getBannerCount()
                  .then(response => {
                    this.pagination_per_page = 20;
                    this.pagination_total = 40;
                    // this.pagination_total = response.data.items;
                  })
    },
   
    async deleteData(id){

      const result = await Swal.fire({
        title: "هل أنت متأكد ؟",
        text: "لن تتمكن من التراجع عن هذا!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، احذفه!"
      });

      if (result.isConfirmed) {
        this.isLoading = true;
        DataService.delete(id)
        .then(response => {
          this.isLoading = false

          DataService.getAll(1)
          .then(response => {
            // this.getUserCount()
            this.items = response.data
          })

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'successfully',
                icon: 'BellIcon',
                text: "تم الحذف بنجاح",
                variant: 'success',
              },
            }
          )
          
        })
      }
    },
    
    getServices(){
      this.isLoading = true;
      DataService.getServices()
      .then(response => {
        this.isLoading = false
        this.serviceType = response.data
      })
    },

    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(newData){
      this.items = newData
    }
  },
  mounted() {
    this.getData(this.currentPage)
    this.getServices()
  },
  
}
</script>